export default {
  USD: 'en-US', // Dólar estadounidense
  AED: 'ar-AE', // Dirham de los Emiratos
  AFN: 'fa-AF', // Afgani afgano
  ALL: 'sq-AL', // Lek albanés
  AMD: 'hy-AM', // Dram armenio
  ANG: 'nl-CW', // Florín antillano neerlandés
  AOA: 'pt-AO', // Kwanza angoleño
  ARS: 'es-AR', // Peso argentino
  AUD: 'en-AU', // Dólar australiano
  AWG: 'nl-AW', // Florín arubeño
  AZN: 'az-AZ', // Manat azerí
  BAM: 'bs-BA', // Marco bosnioherzegovino
  BBD: 'en-BB', // Dólar de Barbados
  BDT: 'bn-BD', // Taka de Bangladés
  BGN: 'bg-BG', // Lev búlgaro
  BIF: 'fr-BI', // Franco burundés
  BMD: 'en-BM', // Dólar de Bermudas
  BND: 'ms-BN', // Dólar de Brunéi
  BOB: 'es-BO', // Boliviano
  BRL: 'pt-BR', // Real brasileño
  BSD: 'en-BS', // Dólar bahameño
  BWP: 'en-BW', // Pula botsuano
  BYN: 'be-BY', // Rublo bielorruso
  BZD: 'en-BZ', // Dólar beliceño
  CAD: 'en-CA', // Dólar canadiense
  CDF: 'fr-CD', // Franco congoleño
  CHF: 'de-CH', // Franco suizo
  CLP: 'es-CL', // Peso chileno
  CNY: 'zh-CN', // Yuan chino
  COP: 'es-CO', // Peso colombiano
  CRC: 'es-CR', // Colón costarricense
  CVE: 'pt-CV', // Escudo caboverdiano
  CZK: 'cs-CZ', // Corona checa
  DJF: 'fr-DJ', // Franco yibutiano
  DKK: 'da-DK', // Corona danesa
  DOP: 'es-DO', // Peso dominicano
  DZD: 'ar-DZ', // Dinar argelino
  EGP: 'ar-EG', // Libra egipcia
  ETB: 'am-ET', // Birr etíope
  EUR: 'de-DE', // Euro
  FJD: 'en-FJ', // Dólar fiyiano
  FKP: 'en-FK', // Libra malvinense
  GBP: 'en-GB', // Libra esterlina
  GEL: 'ka-GE', // Lari georgiano
  GHS: 'en-GH', // Cedi ghanés
  GIP: 'en-GI', // Libra de Gibraltar
  GMD: 'en-GM', // Dalasi gambiano
  GNF: 'fr-GN', // Franco guineano
  GTQ: 'es-GT', // Quetzal guatemalteco
  GYD: 'en-GY', // Dólar guyanés
  HKD: 'zh-HK', // Dólar de Hong Kong
  HNL: 'es-HN', // Lempira hondureño
  HTG: 'fr-HT', // Gourde haitiano
  HUF: 'hu-HU', // Florín húngaro
  IDR: 'id-ID', // Rupia indonesia
  ILS: 'he-IL', // Nuevo shéquel israelí
  INR: 'en-IN', // Rupia india
  ISK: 'is-IS', // Corona islandesa
  JMD: 'en-JM', // Dólar jamaicano
  JPY: 'ja-JP', // Yen japonés
  KES: 'en-KE', // Chelín keniano
  KGS: 'ky-KG', // Som kirguís
  KHR: 'km-KH', // Riel camboyano
  KMF: 'fr-KM', // Franco comorense
  KRW: 'ko-KR', // Won surcoreano
  KYD: 'en-KY', // Dólar caimano
  KZT: 'kk-KZ', // Tenge kazajo
  LAK: 'lo-LA', // Kip laosiano
  LBP: 'ar-LB', // Libra libanesa
  LKR: 'si-LK', // Rupia de Sri Lanka
  LRD: 'en-LR', // Dólar liberiano
  LSL: 'st-LS', // Loti lesotense
  MAD: 'ar-MA', // Dírham marroquí
  MDL: 'ro-MD', // Leu moldavo
  MGA: 'mg-MG', // Ariary malgache
  MKD: 'mk-MK', // Denar macedonio
  MMK: 'my-MM', // Kyat birmano
  MNT: 'mn-MN', // Tugrik mongol
  MOP: 'zh-MO', // Pataca de Macao
  MUR: 'mfe-MU', // Rupia mauriciana
  MVR: 'dv-MV', // Rufiyaa maldivo
  MWK: 'ny-MW', // Kwacha malauí
  MXN: 'es-MX', // Peso mexicano
  MYR: 'ms-MY', // Ringgit malasio
  MZN: 'pt-MZ', // Metical mozambiqueño
  NAD: 'en-NA', // Dólar namibio
  NGN: 'en-NG', // Naira nigeriana
  NIO: 'es-NI', // Córdoba nicaragüense
  NOK: 'nb-NO', // Corona noruega
  NPR: 'ne-NP', // Rupia nepalesa
  NZD: 'en-NZ', // Dólar neozelandés
  PAB: 'es-PA', // Balboa panameño
  PEN: 'es-PE', // Sol peruano
  PGK: 'en-PG', // Kina papú
  PHP: 'en-PH', // Peso filipino
  PKR: 'en-PK', // Rupia pakistaní
  PLN: 'pl-PL', // Zloty polaco
  PYG: 'es-PY', // Guaraní paraguayo
  QAR: 'ar-QA', // Riyal catarí
  RON: 'ro-RO', // Leu rumano
  RSD: 'sr-RS', // Dinar serbio
  RUB: 'ru-RU', // Rublo ruso
  RWF: 'rw-RW', // Franco ruandés
  SAR: 'ar-SA', // Riyal saudí
  SBD: 'en-SB', // Dólar de las Islas Salomón
  SCR: 'fr-SC', // Rupia de Seychelles
  SEK: 'sv-SE', // Corona sueca
  SGD: 'en-SG', // Dólar de Singapur
  SHP: 'en-SH', // Libra santahelenense
  SLE: 'en-SL', // Leone sierraleonés
  SOS: 'so-SO', // Chelín somalí
  SRD: 'nl-SR', // Dólar surinamés
  STD: 'pt-ST', // Dobra santotomense
  SZL: 'en-SZ', // Lilangeni suazi
  THB: 'th-TH', // Baht tailandés
  TJS: 'tg-TJ', // Somoni tayiko
  TOP: 'to-TO', // Paʻanga tongano
  TRY: 'tr-TR', // Lira turca
  TTD: 'en-TT', // Dólar trinitense
  TWD: 'zh-TW', // Nuevo dólar taiwanés
  TZS: 'sw-TZ', // Chelín tanzano
  UAH: 'uk-UA', // Grivna ucraniana
  UGX: 'en-UG', // Chelín ugandés
  UYU: 'es-UY', // Peso uruguayo
  UZS: 'uz-UZ', // Som uzbeko
  VND: 'vi-VN', // Dong vietnamita
  VUV: 'bi-VU', // Vatu vanuatuense
  WST: 'sm-WS', // Tala samoano
  XAF: 'fr-CF', // Franco CFA de África Central
  XCD: 'en-XC', // Dólar del Caribe Oriental
  XOF: 'fr-WA', // Franco CFA de África Occidental
  XPF: 'fr-PF', // Franco CFP
  YER: 'ar-YE', // Rial yemení
  ZAR: 'en-ZA', // Rand sudafricano
  ZMW: 'en-ZM', // Kwacha zambiano
}