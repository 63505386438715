import { doc, onSnapshot } from 'firebase/firestore'
import { defineStore } from 'pinia'
import { db, uploadFileAndGetUrl, uploadFileAndGetObj } from '@/plugins/firebase'
import imagesExtensionsEnum from '@/shared/enums/imagesExtensionsEnum'

export const useDynamicFormStore = defineStore('dynamicFormStore', {
  state: () => ({
    dbDataUser: null,
  }),
  getters: {
    dataUser: state => ({ ...state.dbDataUser }),
  },
  actions: {
    async bindDataUser({ organizationId, projectId }) {
      const docRef = doc(db, 'properties', organizationId, 'projects', projectId, 'forms', 'enrollment')

      const setSnapshot = () => new Promise((resolve, reject) => {
        onSnapshot(docRef, snap => {
          this.dbDataUser = snap.data()
          resolve()
        }, reject)
      })

      await setSnapshot()
    },
    getFormValues({ form, typeUser = null }) {
      return this.dataUser.fields ? this.dataUser.fields.filter(f => typeUser === 'parent' ? f.requestedOnceForAllChildren : !f.requestedOnceForAllChildren)
        .map(formValue => {
          const field = Object.entries(form).find(([key]) => key === formValue?.id)
          return formValue?.label && ({
            id: formValue?.id,
            value: field ? field[1] : null,
          })
        })?.filter(e => e) : []
    },
    async parseForm({ form, storagePath }) {
      return Object.fromEntries(await Promise.all(Object.entries(form)
        .map(async ([key, value]) => {
          if (value instanceof File) {
            return imagesExtensionsEnum.includes(value.name?.split('.').pop())
              ? [key, await uploadFileAndGetUrl(storagePath, value)]
              : [key, await uploadFileAndGetObj(storagePath, value)]
          }
          return [key, value]
        })))
    },
  },
})
