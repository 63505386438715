import { defineStore } from 'pinia'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { db } from '@/plugins/firebase'
import rolesEnum from '@/shared/enums/rolesEnum'

export const useProjectUsersStore = defineStore('projectUsersStore', {
  state: () => ({
    players: [],
    parents: [],
    fans: [],
  }),
  getters: {
    rawSubscriberList() {
      return [
        ...this.players.map(player => ({ ...player, uniqueId: `${player.id}-player` })),
        ...this.parents.map(parent => ({ ...parent, uniqueId: `${parent.id}-parent` })),
      ]
    },
    subscriberEmails() {
      return [...this.rawSubscriberList.map(rS => rS.email).filter(v => v)]
    },
    subscriberExcludeParentEmails() {
      return [...this.players.map(rS => rS.email).filter(v => v)]
    },
  },
  actions: {
    async bindPlayers({ organizationId, projectId }) {
      const colRef = query(collection(db, 'users'), where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains', rolesEnum.PLAYER))
      onSnapshot(colRef, snapshot => { this.players = snapshot.docs.map(playerDoc => playerDoc.data()) })
    },
    async bindParents({ organizationId, projectId }) {
      const colRef = query(collection(db, 'users'), where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains', rolesEnum.PARENT))
      onSnapshot(colRef, snapshot => { this.parents = snapshot.docs.map(parentDoc => parentDoc.data()) })
    },
  },
})
