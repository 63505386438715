import { defineStore } from 'pinia'
import { collection, doc, getDoc, setDoc, onSnapshot } from 'firebase/firestore'
import { db } from '@/plugins/firebase'

export const useOrganizationStore = defineStore('organizationStore', {
  state: () => ({
    organization: null,
  }),
  getters: {
    isISL: state => state.organization?.id === 'Ul4tnunxuyooYpoT6xdN',
  },
  actions: {
    async bindOrganization({ organizationId }) {
      const docRef = doc(db, 'properties', organizationId)

      const setSnapshot = () => new Promise((resolve, reject) => {
        onSnapshot(docRef, snap => {
          this.organization = snap.data()
          resolve()
        }, reject)
      })

      await setSnapshot()
    },
    async readOrganization({ organizationId }) { return (await getDoc(doc(db, 'properties', organizationId))).data() },
    async createLead({ organizationId, data }) {
      const leadsRef = collection(db, 'properties', organizationId, 'leads')
      const docRef = doc(leadsRef, data.email)
      const docSnap = await getDoc(docRef)
      if (!docSnap.exists()) {
        await setDoc(docRef, { id: data.email, ...data })
      }
    },
  },
})
