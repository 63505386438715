import * as Sentry from '@sentry/vue'

export function sentryInit({ app, router }) {
  if (!window.isDev) {
    Sentry.init({
      app,
      dsn: "https://ce6ebd5e6cc800b4c54583795c768ced@o290190.ingest.us.sentry.io/4506144639614976",
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ["https://enrollment.owqlo.com"],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
}