import { defineStore } from 'pinia'
import { getDocs, query, collection, where, updateDoc, doc, serverTimestamp, getDoc, setDoc, runTransaction, Timestamp } from 'firebase/firestore'
import pinia from '@/store'
import { callBackend, db, uploadFileAndGetUrl } from '@/plugins/firebase'
import { useCouponStore } from '@/store/couponStore'
import { useDynamicFormStore } from '@/store/dynamicFormStore'

const couponStore = useCouponStore(pinia)
const dynamicFormStore = useDynamicFormStore(pinia)

export const useUserStore = defineStore('userStore', {
  state: () => ({
    dbData: null,
    cart: {
      paymentItems: {},
    },
    playerId: null,
  }),
  getters: {
    user: state => state.dbData,
  },
  actions: {
    setPlayerId({ playerId }) {
      this.playerId = playerId
    },
    async createAddress({ userId, data }) {
      const addressRef = doc(collection(db, 'users', userId, 'addresses'))
      await setDoc(addressRef, { ...data, id: addressRef.id })
      return addressRef.id
    },
    // Create
    async createWithBackend({ data }) {
      const dataToSave = (({ avatar, phone, email, ...rest }) => ({ ...rest }))(data)
      const { id } = await callBackend('users/create-programatically', {
        data: dataToSave,
        ...(data.email && { email: data.email.toLowerCase() }),
        ...(data.phone && { phone: data.phone }),
      })

      if (data.avatar) {
        const avatarUrl = await uploadFileAndGetUrl(`users/${id}`, data.avatar)
        await updateDoc(doc(db, 'users', id), { avatar: avatarUrl, updatedAt: Timestamp.now() })
      }

      return id
    },
    async createPaymentMethod({ userId, data }) {
      const paymentMethodRef = doc(collection(db, 'users', userId, 'paymentMethods'))
      await setDoc(paymentMethodRef, { ...data, id: paymentMethodRef.id })
      return paymentMethodRef.id
    },
    async createUserCart({ userId, paymentItems, addressId }) {
      const cart = {
        couponId: couponStore?.coupon?.id ?? null,
        paymentItems: {},
        ...(addressId && ({ addressId })),
      }
      paymentItems.forEach((item, i) => {
        const key = `back_${Date.now() + i}`
        cart.paymentItems[key] = item
      })
      await updateDoc(doc(db, 'users', userId), {
        cart,
      })
    },
    async read(id) { return (await getDoc(doc(db, 'users', id))).data() },
    // Read
    async readByEmail(email) {
      if (!email) return null
      const user = []
      const q = query(collection(db, 'users'), where('email', '==', email))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach(doc => { user.push(doc.data()) })
      return user[0] ?? null
    },
    async readMembershipInfo({ id, role, entityType, entityId }) {
      return (await getDoc(doc(db, 'users', id, 'membership', `${role}-${entityType}-${entityId}`))).data()
    },
    // Update
    async update({ id, data }) {
      if (data.avatar) data.avatar = await uploadFileAndGetUrl(`users/${id}`, data.avatar)
      await updateDoc(doc(db, 'users', id), {
        ...data,
        updatedAt: serverTimestamp(),
      })
    },
    async updateProjectMembershipParentFields({ id, role, entityType, entityId, data }) {
      try {
        await runTransaction(db, async transaction => {
          const ref = doc(db, 'users', id, 'membership', `${role}-${entityType}-${entityId}`)
          const storagePath = `users/${id}/membership/subscriber-project-${entityId}/`
          if (data.form) {
            data.form = await dynamicFormStore.parseForm({ form: data.form, storagePath })
            const dataMembership = (await transaction.get(ref)).data()
            const fieldIdsParents = dynamicFormStore.dataUser.fields.filter(f => f.requestedOnceForAllChildren).map(f => f.id)
            const fieldIdsPlayers = dynamicFormStore.dataUser.fields.filter(f => !f.requestedOnceForAllChildren).map(f => f.id)

            const playersForm = Object.fromEntries(Object.entries(dataMembership.form ?? {}).filter(([key]) => fieldIdsPlayers.includes(key)))

            const parentFormValues = await dynamicFormStore.parseForm({ form: data.form, storagePath })
            const parentForm = Object.fromEntries(Object.entries(parentFormValues ?? {}).filter(([key]) => fieldIdsParents.includes(key)))
            data.form = { ...playersForm, ...parentForm }
          }
          transaction.update(ref, { ...data })
        })
      } catch (error) {
        console.error('Transaction failed: ', error)
        return false
      }
    },
  },
})
