import { collection, getDocs, query, where, onSnapshot } from 'firebase/firestore'
import { defineStore } from 'pinia'
import { useProjectStore } from '@/store/projectStore'
import { db } from '@/plugins/firebase'

export const useProductStore = defineStore('productStore', {
  state: () => ({
    products: [],
  }),
  getters: {
    getProductsByFeatured: state => isFeatured => state.products.filter(product => product.featured === isFeatured),
  },
  actions: {
    async bindProducts({ organizationId, projectId }) {
      const compProjectIdsForEnrollment = `organization-${organizationId}--project-${projectId}`
      const q = query(
        collection(db, 'products'),
        where('organizationId', '==', organizationId),
        where('compProjectIdsForEnrollment', 'array-contains', compProjectIdsForEnrollment),
        where('isActive', '==', true),
        where('isPublished', '==', true),
      )

      onSnapshot(q, snapshot => {
        const productDocs = snapshot.docs.map(doc => doc.data())
        const groupedProducts = Object.values(
          productDocs.reduce((acc, item) => {
            const { archetypeId, id, images, name, price, shippingRateIds, suffix, featured, vendorProductId } = item
            if (!acc[archetypeId]) {
              acc[archetypeId] = {
                id,
                archetypeId,
                images,
                name,
                price,
                shippingRateIds,
                featured,
                sizes: [],
                product: null,
                ...(suffix ? { sizes: [] } : { vendorProductId }),
              }
            }
            if (suffix) { acc[archetypeId].sizes.push({ value: id, title: suffix, vendorProductId }) }
            return acc
          }, {}),
        )
        this.products = groupedProducts
      })
    },
    areProductsExtra() {
      const projectStore = useProjectStore()
      return !projectStore.isProjectFree && this.getProductsByFeatured(true).length > 0
    },
  },
})
